import React from 'react';

import WilmingtonBlueRocks from '~/images/inline/wilmington-blue-rocks.svg';
import Impact from '~/images/inline/impact.svg';

const Header = () => {
	return (
		<header className="relative z-50 bg-white">
			<div className="container px-4 py-2 grid gap-4 items-center auto-rows-auto grid-cols-2 lg:grid-cols-3">
				<div className="lg:order-2 lg:self-stretch relative">
					<WilmingtonBlueRocks
						className="w-2/3 sm:w-1/2 lg:w-full lg:absolute lg:top-0"
						aria-hidden="true"
						role="img"
					/>
				</div>
				<div className="lg:order-first">
					<Impact
						className="w-2/3 sm:w-1/2 lg:w-1/2 ml-auto lg:ml-0"
						aria-hidden="true"
						role="img"
					/>
				</div>
				<p className="lg:order-last col-span-2 lg:col-span-1 uppercase text-center lg:text-right">IMPACT Night: <time dateTime="2024-04-20T18:35:00.000Z" className="normal-case">June 28, 2025</time></p>
			</div>
		</header>
	);
};

export default Header;
